import { Link } from "react-router-dom";
import ButterflyLogoAlt from "../images/butterfly-logo-alt-wh.svg";

export default function BodyContent() {
  return (
    <>
      <section className='row'>
        <div className='col-1-1 main-content'>
          <img
            className='main-logo'
            aria-label='Butterfly Mirror Logo'
            src={ButterflyLogoAlt}
            alt='Butterfly Mirror Logo'
          />
          <h1 className='headline-primary'>web design & development</h1>
          <Link className='main-cta' to='/contact'>
            <button className='btn'>Learn More</button>
          </Link>
        </div>
      </section>
      <section className='services col-1-1'>
        <div className='services__image section__image'></div>
        <h2 className='headline__secondary'>What We Do</h2>

        <h3>Web designs and develpment to elevate your online presence.</h3>
        <ul className='services__list'>
          <li className='services__item'>
            <span class='material-symbols-outlined'>arrow_circle_right</span>
            <p>
              Web site design utilizing the latest UI and accessibility
              standards
            </p>
          </li>
          <li className='services__item'>
            <span class='material-symbols-outlined'>arrow_circle_right</span>
            <p>Web site development for all modern browsers and devices</p>
          </li>
          <li className='services__item'>
            <span class='material-symbols-outlined'>arrow_circle_right</span>
            <p>Email newsletters and campaigns</p>
          </li>
          <li className='services__item'>
            <span class='material-symbols-outlined'>arrow_circle_right</span>
            <p>Search engine optimization and analytics</p>
          </li>
          <li className='services__item'>
            <span class='material-symbols-outlined'>arrow_circle_right</span>
            <p>Updates and maintence plans after going live</p>
          </li>
        </ul>
      </section>
    </>
  );
}
