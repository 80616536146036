const About = () => {
  return (
    <section className='about col-1-1'>
      <h2>Lorem ipsum dolor sit amet consectetur</h2>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, autem earum quaerat hic ex reprehenderit fuga vel quos veniam magnam illum odio harum odit assumenda a nihil alias. Possimus, animi?
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur perferendis similique dolorem sint nostrum aliquam nobis at. Sapiente minus, distinctio et recusandae quos deleniti eos, perspiciatis doloremque optio, excepturi aperiam?
      </p>
      <p>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloribus vel, eum illo cumque veritatis rem omnis culpa perspiciatis voluptatum voluptatibus numquam minus a iusto quidem eius consequatur quam fugit nihil.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur sequi perferendis, praesentium magni ex fuga ducimus itaque iure deserunt maxime, maiores veritatis consequatur sapiente quis tenetur velit, molestias distinctio corporis.
      </p>
    </section>
  );
};

export default About;
