export default function Contact() {
  return (
    <>
      <section className='contact col-1-1'>
        <h2>Contact Form</h2>
        <p>
          If you're interested in partnering with us and seeing how we can help
          your business, charity, or any other venture, please complete and
          submit this form. We'll be in contact withing 24 hours.
        </p>
        <p>
          We can also be reached by phone: 989.390-2245, email:
          info@butterflymirrors.com, and Facebook.
        </p>
        <form
          className='contact-form'
          onsubmit='sendEmail(); reset(); return false'
        >
          <label className='contact-form__label' htmlFor='name'>
            Name
          </label>
          <input
            className='contact-form__text'
            type='text'
            name='name'
            id='name'
            placeholder='First & Last Name'
          />
          <label className='contact-form__label' htmlFor='email'>
            Email
          </label>
          <input
            className='contact-form__text'
            type='email'
            name='email'
            id='email'
            placeholder='Email Address'
          />
          <label className='contact-form__label' htmlFor='message'>
            How can we help you?
          </label>
          <textarea
            className='contact-form__text'
            name='message'
            id='message'
            cols='30'
            rows='10'
            placeholder='Message'
          ></textarea>
          <button className='btn contact-form__button'>Send</button>
        </form>
      </section>
    </>
  );
}
